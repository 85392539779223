* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  /*background-color: #000;*/
  background-color: linear-gradient(135deg, #1f1c2c, #928DAB);
}
